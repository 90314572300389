.header {
  width: 150px;
  position: relative;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--light-lavender);
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.header:hover {
  background-color: var(--mid-lavender);
}

.select-options {
  width: 150px;
  background-color: var(--light-lavender);
  border-radius: 10px;
  position: absolute;
  top: 45px;
  height: fit-content;
  max-height: 305px;
  overflow-y: auto;
  border: 1px solid var(--mid-lavender);
}

.select-option {
  width: 100%;
  height: 50px !important;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  margin: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.select-option:hover {
  background-color: var(--mid-lavender);
}
