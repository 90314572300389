.basic-page {
  min-height: calc(100vh - 300px);
  width: 100%;
  background-color: var(--dark-lavender);
  height: auto;

  /* For the top navbar and main navbar */
  margin-top: 100px;
  padding-bottom: 50px;
}

.no-main-navbar {
  margin-top: 50px;
}

.account-input {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 20px;
  height: 40px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0 10px 0px 15px;
}

.account-input-control::placeholder {
  color: rgb(235, 235, 235) !important;
}

.login-button {
  background: rgb(0, 24, 158) !important;
  border: 0 !important;
  width: 50%;
  height: 36px;
  border-radius: 18px !important;
}

.login-button:hover {
  background: rgb(1, 22, 139) !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.cart-details-container {
  width: 80%;
  height: fit-content;
  text-align: left;
}

.admin-filter {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
}

.skin {
  width: 250px;
  border-radius: 10px !important;
  border: none !important;
  overflow: hidden;
  background: var(--light-lavender) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.skin:hover {
  background: var(--mid-lavender) !important;
}

.skin-image {
  border-radius: 0 !important;
}

.skin-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--mid-lavender) !important;
}

.float {
  color: #ccc;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.mid-lavender-background {
  background: var(--mid-lavender) !important;
}

.dark-lavender-background {
  background: var(--dark-lavender) !important;
}
