.dropdown:hover .dropdown-menu {
  /* display: block; */
  margin-top: 0;
  margin-left: 0px !important;
}

.custom-dropdown:hover .dropdown-menu {
  overflow: auto;
  max-height: calc(100vh - 10rem);
}

.dropdown-menu {
  border: none !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 0 !important;
}

.form-select {
  background-color: #f6f5f5 !important;
  transition: all 0.2s ease-in-out !important;
  border: none !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-select:hover {
  background-color: #eeeeee !important;
  cursor: pointer;
}

.colorful {
  font-weight: bold;
  text-decoration: none;
  background-image: linear-gradient(to right, #ff8800, #e52e71);
  background-clip: text; /* This property applies the background to the text */
  -webkit-background-clip: text; /* For Safari/Chrome browsers */
  color: transparent; /* Make the text transparent so the background shows through */
}

.cover {
  width: 100%;
  height: 200px !important;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: var(--mid-lavender) !important;
}

.search-icon {
  transition: all 0.2s ease-in-out;
}

.search-icon:hover {
  color: rgb(20, 196, 219);
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo:hover {
  cursor: pointer;
}

.purchase-modal-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase-modal {
  width: fit-content;
  height: fit-content;
  background-color: var(--light-lavender);
  border-radius: 50px;
  display: flex;
  align-items: flex-start;
  padding: 0 50px 50px 50px;
  flex-direction: column;
  color: #ccc;
}

.purchase-modal p {
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.purchase-modal span {
  font-weight: bold;
}

.purchase-modal h4 {
  margin: 40px 0 20px 0;
}

.radios {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.radios .radio {
  margin-right: 30px;
}

.purchase-button {
  margin-right: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.visualizer {
  width: 100%;
  height: 5px;
  border-radius: 60px;
  background: linear-gradient(to right, green 0%, green 7%, rgb(0, 202, 0) 7%, rgb(0, 202, 0) 15%, orange 15%, orange 38%, rgb(255, 46, 46) 38%, rgb(255, 46, 46) 45%, rgb(171, 0, 0) 45%, rgb(171, 0, 0) 100%);
}

.top-navbar {
  font-size: 15px;
  position: fixed !important;
  width: 100%;
  top: 0;
  height: 50px;
  z-index: 100;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background: var(--light-lavender);
}

.main-navbar {
  background: var(--mid-lavender) !important;
}

.search-button {
  position: absolute;
  right: 50px;
  background-color: var(--dark-lavender);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  transition: all 0.2s ease-in-out;
}

.search-button:hover {
  background-color: var(--darker-lavender);
  cursor: pointer;
}

.search-input {
  position: absolute;
  right: 80px;
  width: 150px !important;
  height: 40px;
  background: var(--dark-lavender) !important;
  border: none !important;
  color: #ccc !important;
}

.price-input {
  width: 100px !important;
  height: 40px;
  background: var(--mid-lavender) !important;
  border: none !important;
  color: #ccc !important;
  border-radius: 10px !important;
}

.price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: 2px solid var(--mid-lavender);
  padding: 10px;
  border-radius: 10px;
}

.price-search-button {
  position: relative !important;
  right: auto;
  background-color: var(--darker-lavender);
  border-radius: 10px;
}

.price-search-button:hover {
  background-color: var(--dark-lavender);
  cursor: pointer;
}