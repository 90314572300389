.custom-dropdown {
  position: relative;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options {
  position: absolute;
  background: var(--light-lavender);
  top: 50px;
}

.sub-options {
  width: 150px;
  background: var(--light-lavender);
  position: absolute;
  left: 150px;
}

.option {
  width: 100%;
  height: 50px;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.sub-option {
  width: 100%;
  height: 50px;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.sub-option:hover {
  background: var(--mid-lavender) !important;
}

.option:hover {
  background: var(--mid-lavender);
}
