.dialog-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.dialog {
  width: 30%;
  height: fit-content;
  background: var(--mid-lavender);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
  font-size: 20px;
  border-radius: 20px;
  padding: 30px 20px;
}
