.deposited-label {
  background-color: gray;
  width: 80px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: -20px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
}
